import { defineStore } from "pinia";
import * as lcache from "@/util/localCache";

export const useUserStateStore = defineStore("userStateStore", {
  state: () => {
    const currentMyTicketsState = {};
    const currentFacilityViewState = {};
    const currentDetailedFacilityViewState = {};
    const currentTicketViewState = {};
    const currentTicketViewPSAState = {};
    const currentParentCompanyState = {};
    const currentDefectTicketViewState = {};
    const currentContactsViewState = {};
    const currentUsersViewState = {};
    const currentConsultantSchedulingViewState = {};
    const currentParentCompanyTicketViewState = {};
    const currentMyScheduleViewState = {};
    return {
      currentMyTicketsState,
      currentFacilityViewState,
      currentDetailedFacilityViewState,
      currentTicketViewState,
      currentParentCompanyTicketViewState,
      currentTicketViewPSAState,
      currentParentCompanyState,
      currentDefectTicketViewState,
      currentContactsViewState,
      currentUsersViewState,
      currentConsultantSchedulingViewState,
      currentMyScheduleViewState,
    };
  },
  getters: {
    myTicketsState: (state) => state.currentMyTicketsState,
    facilityViewState: (state) => state.currentFacilityViewState,
    detailedFacilityViewState: (state) =>
      state.currentDetailedFacilityViewState,
    ticketViewState: (state) => state.currentTicketViewState,
    parentCompanyTicketViewState: (state) =>
      state.currentParentCompanyTicketViewState,
    ticketViewPSAState: (state) => state.currentTicketViewPSAState,
    parentCompanyState: (state) => state.currentParentCompanyState,
    defectTicketViewState: (state) => state.currentDefectTicketViewState,
    contactsViewState: (state) => state.currentContactsViewState,
    usersViewState: (state) => state.currentUsersViewState,
    consultantSchedulingViewState: (state) =>
      state.currentConsultantSchedulingViewState,
    myScheduleViewState: (state) => state.currentMyScheduleViewState,
  },
  actions: {
    async loadMyTicketsState(userEmail) {
      const key = "myTicketsStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentMyTicketsState = cacheValue;
      }
      return null;
    },
    async setMyTicketsState(userEmail, inputState) {
      const key = "myTicketsStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentMyTicketsState = inputState;
    },
    async clearMyTicketsState(userEmail) {
      const key = "myTicketsStateKey" + userEmail;
      lcache.Clear(key);
      this.currentMyTicketsState = {};
    },

    async loadFacilityViewState(userEmail) {
      const key = "facilityViewStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentFacilityViewState = cacheValue;
      }
      return null;
    },
    async setFacilityViewState(userEmail, inputState) {
      const key = "facilityViewStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentFacilityViewState = inputState;
    },
    async clearFacilityViewState(userEmail) {
      const key = "facilityViewStateKey" + userEmail;
      lcache.Clear(key);
      this.currentFacilityViewState = {};
    },

    async loadDetailedFacilityViewState(userEmail) {
      const key = "facilityDetailedViewStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentDetailedFacilityViewState = cacheValue;
      }
      return null;
    },
    async setDetailedFacilityViewState(userEmail, inputState) {
      const key = "facilityDetailedViewStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentDetailedFacilityViewState = inputState;
    },
    async clearDetailedFacilityViewState(userEmail) {
      const key = "facilityDetailedViewStateKey" + userEmail;
      lcache.Clear(key);
      this.currentDetailedFacilityViewState = {};
    },

    async loadTicketViewState(userEmail) {
      const key = "ticketViewStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentTicketViewState = cacheValue;
      }
      return null;
    },
    async setTicketViewState(userEmail, inputState) {
      const key = "ticketViewStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentTicketViewState = inputState;
    },
    async clearTicketViewState(userEmail) {
      const key = "ticketViewStateKey" + userEmail;
      lcache.Clear(key);
      this.currentTicketViewState = {};
    },
    async loadParentCompanyTicketViewState(userEmail) {
      const key = "parentCompanyTicketViewStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentParentCompanyTicketViewState = cacheValue;
      }
      return null;
    },
    async setParentCompanyTicketViewState(userEmail, inputState) {
      const key = "parentCompanyTicketViewStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentParentCompanyTicketViewState = inputState;
    },
    async clearParentCompanyTicketViewState(userEmail) {
      const key = "parentCompanyTicketViewStateKey" + userEmail;
      lcache.Clear(key);
      this.currentParentCompanyTicketViewState = {};
    },
    async loadTicketViewPSAState(userEmail) {
      const key = "ticketViewPSAStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentTicketViewPSAState = cacheValue;
      }
      return null;
    },
    async setTicketViewPSAState(userEmail, inputState) {
      const key = "ticketViewPSAStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentTicketViewPSAState = inputState;
    },
    async clearTicketViewPSAState(userEmail) {
      const key = "ticketViewPSAStateKey" + userEmail;
      lcache.Clear(key);
      this.currentTicketViewPSAState = {};
    },

    async loadParentCompanyState(userEmail) {
      const key = "parentCompanyStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentParentCompanyState = cacheValue;
      }
      return null;
    },
    async setParentCompanyState(userEmail, inputState) {
      const key = "parentCompanyStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentParentCompanyState = inputState;
    },
    async clearParentCompanyState(userEmail) {
      const key = "parentCompanyStateKey" + userEmail;
      lcache.Clear(key);
      this.currentParentCompanyState = {};
    },

    async loadDefectTicketViewState(userEmail) {
      const key = "defectTicketViewStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentDefectTicketViewState = cacheValue;
      }
      return null;
    },
    async setDefectTicketViewState(userEmail, inputState) {
      const key = "defectTicketViewStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentDefectTicketViewState = inputState;
    },
    async clearDefectTicketViewState(userEmail) {
      const key = "defectTicketViewStateKey" + userEmail;
      lcache.Clear(key);
      this.currentDefectTicketViewState = {};
    },

    async loadContactsViewState(userEmail) {
      const key = "contactsViewStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentContactsViewState = cacheValue;
      }
      return null;
    },
    async setContactsViewState(userEmail, inputState) {
      const key = "contactsViewStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentContactsViewState = inputState;
    },
    async clearContactsViewState(userEmail) {
      const key = "contactsViewStateKey" + userEmail;
      lcache.Clear(key);
      this.currentContactsViewState = {};
    },
    async loadUsersViewState(userEmail) {
      const key = "usersViewStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentUsersViewState = cacheValue;
      }
      return null;
    },
    async setUsersViewState(userEmail, inputState) {
      const key = "usersViewStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentUsersViewState = inputState;
    },
    async clearUsersViewState(userEmail) {
      const key = "usersViewStateKey" + userEmail;
      lcache.Clear(key);
      this.currentUsersViewState = {};
    },
    async loadConsultantSchedulingViewState(userEmail) {
      const key = "consultantSchedulingStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentConsultantSchedulingViewState = cacheValue;
      }
      return null;
    },
    async setConsultantSchedulingViewState(userEmail, inputState) {
      const key = "consultantSchedulingStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentConsultantSchedulingViewState = inputState;
    },
    async clearConsultantSchedulingViewState(userEmail) {
      const key = "consultantSchedulingStateKey" + userEmail;
      lcache.Clear(key);
      this.currentConsultantSchedulingViewState = {};
    },
    async loadMyScheduleViewState(userEmail) {
      const key = "myScheduleStateKey" + userEmail;
      const cacheValue = lcache.GetNoExpiry(key);
      if (cacheValue) {
        this.currentMyScheduleViewState = cacheValue;
      }
      return null;
    },
    async setMyScheduleViewState(userEmail, inputState) {
      const key = "myScheduleStateKey" + userEmail;
      lcache.AddNoExpiry(key, inputState);
      this.currentMyScheduleViewState = inputState;
    },
    async clearMyScheduleViewState(userEmail) {
      const key = "myScheduleStateKey" + userEmail;
      lcache.Clear(key);
      this.currentMyScheduleViewState = {};
    },
  },
});
