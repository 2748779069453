<template>
  <div>
    <div v-if="!editing || !canEdit" :class="viewClassList">
      <router-link
        v-if="isAssigned"
        :to="{
          name: 'Facility',
          params: { facilityId: `${id}` },
        }"
      >
        {{ name }}
      </router-link>
      <div v-else>{{ emptyText }}</div>
    </div>
    <AppAutocomplete
      v-if="editing && canEdit"
      :placeholder="label"
      append-icon
      prepend-inner-icon="mdi-magnify"
      v-model="facility"
      :items="facilityList"
      item-text="displayName"
      item-value="id"
      return-object
      clearable
      dense
      :rules="rules"
      :hide-details="hideDetails"
    ></AppAutocomplete>
  </div>
</template>

<script>
import AppAutocomplete from "@/components/AppAutocomplete";
import { globalEventHub } from "@/util/globalEvents";
import { useFacilityListStore } from "@/store/facilityListStore";
import { formatFacilityNameForSearch } from "@/logic/FacilityLogic";

export default {
  name: "FacilitySelect",
  components: {
    AppAutocomplete,
  },
  props: {
    canEdit: {
      type: Boolean,
      default: true,
    },
    editing: {
      type: Boolean,
      default: false,
    },
    viewClassList: {
      type: String,
      default: "",
    },
    value: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: "Facility",
    },
    rules: {
      type: Array,
      default: () => [],
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    emptyText: {
      type: String,
      default: "Unassigned",
    },
    limitPharmacy: {
      type: Boolean,
      default: false,
    },
    limitRehab: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    isAssigned() {
      return this.value?.name != null;
    },
    name() {
      return this.value?.name ?? "";
    },
    id() {
      return this.value?.id ?? 0;
    },
    facilityList() {
      const facilityListStore = useFacilityListStore();
      let returnList = [];
      if (this.limitPharmacy) {
        returnList = facilityListStore.pharmacyFacilityOptions ?? [];
      } else if (this.limitRehab) {
        returnList = facilityListStore.rehabFacilityOptions ?? [];
      } else {
        returnList = facilityListStore.allFacilityOptions ?? [];
      }

      // handle the case where the currently selected facility is not in the list
      if (
        this.value &&
        this.value.id &&
        !returnList.some((f) => f.id === this.value.id)
      ) {
        returnList.push(this.value);
      }

      return formatFacilityNameForSearch(returnList);
    },
    facility: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {},
  mounted: async function () {
    const facilityListStore = useFacilityListStore();
    try {
      let resp = await facilityListStore.loadFacilityOptions();
      if (resp !== null) {
        globalEventHub.emitErrorEvent(resp, resp, "FacilitySelect");
      }
    } catch (err) {
      globalEventHub.emitErrorEvent(
        "Unable to load facilities.",
        err,
        "FacilitySelect"
      );
    }
  },
};
</script>
