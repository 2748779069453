<template>
  <v-row no-gutters>
    <v-col cols="12" sm="6" md="4" lg="2" :class="actualTitleClass">
      {{ title }}
      <sup v-if="required" class="error--text"> * </sup>
    </v-col>
    <v-col cols="12" sm="6" :md="mdBody" :lg="lgBody" class="data-row-content">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DataRow",
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    titleClass: {
      type: String,
      default: "font-weight-medium",
    },
    center: {
      type: Boolean,
      default: false,
    },
    short: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({}),
  computed: {
    actualTitleClass() {
      return (
        this.titleClass + " pr-2 " + (this.center ? "d-flex align-center" : "")
      );
    },
    mdBody() {
      return this.short ? 4 : 8;
    },
    lgBody() {
      return this.short ? 3 : this.full ? 10 : 6;
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.data-row-content {
  color: var(--v-black-base);
}
</style>
