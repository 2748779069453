<template>
  <div>
    <span v-if="!isEditing">{{ displayOnlyValue }}</span>
    <v-select
      v-if="isEditing"
      :placeholder="selectPlaceholder"
      v-model="internalValue"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      return-object
      outlined
      dense
      :rules="rules"
      :append-icon="appendIcon"
      :hide-details="hideDetails"
      v-bind="$attrs"
      :clearable="clearable"
    >
    </v-select>
  </div>
</template>

<script>
export default {
  name: "FieldSelect",
  components: {},
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Object, String],
      default: () => null,
    },
    selectPlaceholder: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "id",
    },
    displayValue: {
      type: String,
      default: "",
    },
    emptyValue: {
      type: String,
      default: "",
    },
    appendIcon: {
      type: String,
      default: "mdi-chevron-down",
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    internalValue: {},
  }),
  computed: {
    displayOnlyValue() {
      if (this.displayValue.length > 0) {
        return this.displayValue;
      }
      return this.value?.name ?? this.value ?? this.emptyValue;
    },
  },
  watch: {
    internalValue: {
      handler() {
        this.$emit("input", this.internalValue);
      },
    },
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    editing: {
      handler() {
        this.internalValue = this.value;
      },
    },
  },
};
</script>
