<template>
  <v-navigation-drawer
    v-model="innerDrawer"
    app
    clipped
    width="256"
    height="100%"
  >
    <v-list class="mt-0 mb-0" dense nav>
      <v-list-item
        v-for="item in myTasks"
        :key="item.title"
        link
        :to="item.url"
      >
        <v-list-item-icon class="me-4">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="text-subtitle-2">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list class="mb-0" dense nav>
      <v-list-item
        v-for="item in myDashboard"
        :key="item.title"
        link
        :to="item.url"
        :class="itemClass(item.subItem)"
      >
        <v-list-item-icon :class="iconClass(item.subItem)">
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content :class="contentClass(item.subItem)">
          <v-list-item-title class="text-subtitle-2">
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <div v-if="isDataAdmin">
      <v-divider></v-divider>
      <v-list class="mb-8" dense>
        <v-list-item key="Settings">
          <v-list-item-icon class="me-4">
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="text-subtitle-2">
              Settings
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-for="item in dataAdminItems"
          :key="item.title"
          link
          :to="item.url"
          :class="itemClass(item.subItem)"
        >
          <v-list-item-icon :class="iconClass(item.subItem)">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content :class="contentClass(item.subItem)">
            <v-list-item-title class="text-subtitle-2">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { useLoginStore } from "@/store/loginStore";
import * as BatchJob from "@/permissions/batchJob";
import * as rehab from "@/permissions/rehab";
import * as DataAdmin from "@/permissions/dataAdmin";

export default {
  name: "SideNav",
  data() {
    return {
      innerDrawer: false,

      myTasks: [
        {
          title: "My Tickets",
          icon: "mdi-briefcase-account-outline",
          url: "/",
        },
        {
          title: "My Schedule",
          icon: "mdi-table-clock",
          url: "/MySchedule",
        },
      ],
      resources: [
        { title: "Reports", icon: "mdi-poll" },
        { title: "RX News", icon: "mdi-bullhorn-outline" },
      ],
    };
  },
  methods: {
    iconClass(subItem) {
      if (subItem == 1) return "ms-6 me-4";
      if (subItem == 2) return "ms-10 me-4";
      return "me-4";
    },
    contentClass(subItem) {
      if (subItem == 1) return "";
      if (subItem == 2) return "";
      return "";
    },
    itemClass(subItem) {
      if (subItem == 1) return "mt-n1";
      if (subItem == 2) return "mt-n2";
      return "";
    },
  },
  computed: {
    isDataAdmin() {
      const ls = useLoginStore();
      return ls.hasPermission(DataAdmin.DataAdmin);
    },
    dataAdminItems() {
      var items = [];
      if (this.isDataAdmin) {
        items.push({
          title: "Service Providers",
          icon: "mdi-database-outline",
          url: "/serviceproviders",
          subItem: 1,
        });
        items.push({
          title: "EHRs",
          icon: "mdi-database-outline",
          url: "/ehrs",
          subItem: 1,
        });
      }
      return items;
    },
    myDashboard() {
      const ls = useLoginStore();

      var items = [];
      items.push({
        title: "Parent Companies",
        icon: "mdi-briefcase-variant-outline",
        url: "/parentcompanies",
      });

      items.push({
        title: "Parent Company Tickets",
        icon: "mdi-clipboard-list-outline",
        url: "/parentcompanytickets",
        subItem: 1,
      });

      items.push({
        title: "Facilities",
        icon: "mdi-account-group-outline",
        url: "/facilities",
      });

      items.push({
        title: "Detailed Facilities",
        icon: "mdi-account-details-outline",
        url: "/facilitiesdetailed",
        subItem: 1,
      });

      items.push({
        title: "Facility Tickets",
        icon: "mdi-clipboard-list-outline",
        url: "/tickets",
        subItem: 1,
      });

      if (!ls.hasPermission(rehab.rehabAreaDirector)) {
        items.push({
          title: "PSA",
          icon: "mdi-clipboard-alert-outline",
          url: "/psa",
          subItem: 2,
        });

        items.push({
          title: "Defects",
          icon: "mdi-clipboard-remove-outline",
          url: "/defects",
          subItem: 2,
        });
      }

      items.push({
        title: "Contacts",
        icon: "mdi-account-circle-outline",
        url: "/contacts",
      });

      items.push({
        title: "Users",
        icon: "mdi-account-multiple-outline",
        url: "/UserList",
      });

      if (ls.hasPermission(BatchJob.ViewBatchJob)) {
        items.push({
          title: "Jobs",
          icon: "mdi-progress-wrench",
          url: "/Job",
        });
      }

      items.push({
        title: "Consultant Schedules",
        icon: "mdi-table-clock",
        url: "/ConsultantSchedules",
      });

      return items;
    },
  },
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    drawer: {
      handler() {
        this.innerDrawer = this.drawer;
      },
      immediate: true,
    },
    innerDrawer: {
      handler() {
        this.$emit("update:drawer", this.innerDrawer);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item__title {
  color: var(--v-darkGrey-base);
}
.list-item-section__title {
  color: var(--v-darkGrey-lighten3);
}

.v-list-item--active {
  background-color: var(--v-primary-lighten5);

  .v-list-item__title,
  .v-list-item__icon {
    color: var(--v-primary-base);
  }
}
</style>
