import * as userStateStore from "@/store/userStateStore";

function loadCurrentStateFromStore(email, fromPage) {
  let uss = userStateStore.useUserStateStore();
  if (fromPage === "My Tickets") {
    uss.loadMyTicketsState(email);
    return uss.myTicketsState;
  }
  if (fromPage === "Facility View") {
    uss.loadFacilityViewState(email);
    return uss.facilityViewState;
  }
  if (fromPage === "Detailed Facility View") {
    uss.loadDetailedFacilityViewState(email);
    return uss.detailedFacilityViewState;
  }
  if (fromPage === "Ticket View") {
    uss.loadTicketViewState(email);
    return uss.ticketViewState;
  }
  if (fromPage === "Parent Company Ticket View") {
    uss.loadParentCompanyTicketViewState(email);
    return uss.parentCompanyTicketViewState;
  }
  if (fromPage === "PSA Ticket View") {
    uss.loadTicketViewPSAState(email);
    return uss.ticketViewPSAState;
  }
  if (fromPage === "Parent Company View") {
    uss.loadParentCompanyState(email);
    return uss.parentCompanyState;
  }
  if (fromPage === "Defect Ticket View") {
    uss.loadDefectTicketViewState(email);
    return uss.defectTicketViewState;
  }
  if (fromPage === "Contacts View") {
    uss.loadContactsViewState(email);
    return uss.contactsViewState;
  }
  if (fromPage === "Users View") {
    uss.loadUsersViewState(email);
    return uss.usersViewState;
  }
  if (fromPage === "Consultant Scheduling View") {
    uss.loadConsultantSchedulingViewState(email);
    return uss.consultantSchedulingViewState;
  }
  if (fromPage === "My Schedule View") {
    uss.loadMyScheduleViewState(email);
    return uss.myScheduleViewState;
  }
  return {};
}
function getIndividualFilter(keyName, largeObject) {
  let tempCopy = largeObject.find((x) => x.id === keyName)?.value ?? [];
  return Object.values(tempCopy);
}
function getIndividualFilterAsString(keyName, largeObject) {
  let tempCopy = largeObject.find((x) => x.id === keyName)?.value ?? "";
  return Object.values(tempCopy).join("");
}
function getIndividualFilterAsObject(keyName, largeObject) {
  return largeObject.find((x) => x.id === keyName)?.value ?? {};
}
function getIndividualFilterAsPrimitive(keyName, largeObject) {
  return largeObject.find((x) => x.id === keyName)?.value?.value ?? null;
}
function saveFiltersToCache(email, currentState, fromPage) {
  let uss = userStateStore.useUserStateStore();
  if (fromPage === "My Tickets") {
    uss.setMyTicketsState(email, currentState);
  }
  if (fromPage === "Facility View") {
    uss.setFacilityViewState(email, currentState);
  }
  if (fromPage === "Detailed Facility View") {
    uss.setDetailedFacilityViewState(email, currentState);
  }
  if (fromPage === "Ticket View") {
    uss.setTicketViewState(email, currentState);
  }
  if (fromPage === "Parent Company Ticket View") {
    uss.setParentCompanyTicketViewState(email, currentState);
  }
  if (fromPage === "PSA Ticket View") {
    uss.setTicketViewPSAState(email, currentState);
  }
  if (fromPage === "Parent Company View") {
    uss.setParentCompanyState(email, currentState);
  }
  if (fromPage === "Defect Ticket View") {
    uss.setDefectTicketViewState(email, currentState);
  }
  if (fromPage === "Contacts View") {
    uss.setContactsViewState(email, currentState);
  }
  if (fromPage === "Users View") {
    uss.setUsersViewState(email, currentState);
  }
  if (fromPage === "Consultant Scheduling View") {
    uss.setConsultantSchedulingViewState(email, currentState);
  }
  if (fromPage === "My Schedule View") {
    uss.setMyScheduleViewState(email, currentState);
  }
}
function clearAllFilters(email) {
  let uss = userStateStore.useUserStateStore();
  uss.clearMyTicketsState(email);
  uss.clearFacilityViewState(email);
  uss.clearDetailedFacilityViewState(email);
  uss.clearTicketViewState(email);
  uss.clearParentCompanyTicketViewState(email);
  uss.clearTicketViewPSAState(email);
  uss.clearParentCompanyState(email);
  uss.clearDefectTicketViewState(email);
  uss.clearContactsViewState(email);
  uss.clearUsersViewState(email);
  uss.clearConsultantSchedulingViewState(email);
  uss.clearMyScheduleViewState(email);
}

function updateCurrentStatePrimitive(id, value, currentState) {
  return updateCurrentState(id, { value }, currentState);
}

function updateCurrentState(id, value, currentState) {
  // Check if the id already exists in the array
  const index = currentState.findIndex((item) => item.id === id);

  // Create a copy of the value to avoid reference issues
  const valueCopy = { ...value };

  if (index !== -1) {
    // If the id exists, update the value at that index
    currentState[index].value = valueCopy;
  } else {
    // If the id doesn't exist, push a new object to the array
    currentState.push({ id, value: valueCopy });
  }
  return currentState;
}
export {
  loadCurrentStateFromStore,
  getIndividualFilter,
  getIndividualFilterAsString,
  getIndividualFilterAsObject,
  saveFiltersToCache,
  updateCurrentState,
  clearAllFilters,
  getIndividualFilterAsPrimitive,
  updateCurrentStatePrimitive,
};
