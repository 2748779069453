import Vue from "vue";
import VueRouter from "vue-router";
import ticketTypes from "@/constants/ticketTypes";
import * as lastRoute from "@/util/lastRoute";
import { isUpToDate } from "@/util/serviceWorkerState";
import { logInformation } from "@/util/logger";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "MyTickets",
    component: () => import("../views/Tickets/AssignedTickets.vue"),
  },
  {
    path: "/parentcompanies",
    name: "ParentCompanies",
    component: () => import("../views/ParentCompanies.vue"),
  },
  {
    path: "/parentcompanies/createparentcompany",
    name: "CreateParentCompany",
    component: () => import("../views/CreateParentCompany.vue"),
  },
  {
    path: "/parentcompanies/:parentCompanyId",
    name: "ParentCompany",
    props: (p) => ({ id: parseInt(p.params.parentCompanyId) }),
    component: () => import("../views/ParentCompany.vue"),
  },
  {
    path: "/parentcompanies/edit/:parentCompanyId",
    name: "EditParentCompany",
    props: (p) => ({ id: parseInt(p.params.parentCompanyId) }),
    component: () => import("../views/EditParentCompany.vue"),
  },
  {
    path: "/facilities",
    name: "Facilities",
    component: () => import("../views/Facilities.vue"),
  },
  {
    path: "/facilitiesDetailed",
    name: "Facilities Detailed List",
    component: () => import("../views/FacilityDetailed.vue"),
  },
  {
    path: "/facilities/createfacility",
    name: "CreateFacility",
    props: (p) => ({ parentCompanyId: parseInt(p.params.parentCompanyId) }),
    component: () => import("../views/CreateFacility.vue"),
  },
  {
    path: "/facilities/:facilityId",
    name: "Facility",
    props: (p) => ({ id: parseInt(p.params.facilityId) }),
    component: () => import("../views/Facility.vue"),
  },
  {
    path: "/facilities/:facilityId/CAR/create",
    name: "CreateCARTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      facilityId: parseInt(p.params.facilityId),
      ticketType: ticketTypes.car,
    }),
    component: () => import("../views/Tickets/CreateCarTicket.vue"),
  },
  {
    path: "/parentcompanies/:parentCompanyId/CARParent/create",
    name: "CreateCARParentCompanyTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      parentCompanyId: parseInt(p.params.parentCompanyId),
      ticketType: ticketTypes.parentCar,
    }),
    component: () =>
      import("../views/Tickets/CreateCarParentCompanyTicket.vue"),
  },
  {
    path: "/facilities/:ticketType/create",
    name: "CreateDefectTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      ticketType: ticketTypes.defect,
      facilityId: parseInt(p.params.facilityId),
    }),
    component: () => import("../views/Tickets/CreateDefectTicket.vue"),
  },
  {
    path: "/tickets/task/create",
    name: "CreateTaskTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      ticketType: ticketTypes.task,
      facilityId: parseInt(p.params.facilityId),
    }),
    component: () => import("../views/Tickets/CreateTaskTicket.vue"),
  },
  {
    path: "/parentcompanytickets/taskParent/create",
    name: "CreateTaskParentCompanyTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      ticketType: ticketTypes.parentTask,
      parentCompanyId: parseInt(p.params.parentCompanyId),
    }),
    component: () =>
      import("../views/Tickets/CreateTaskParentCompanyTicket.vue"),
  },
  {
    path: "/psa/create",
    name: "CreatePSATicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      ticketType: ticketTypes.psa,
      facilityId: parseInt(p.params.facilityId),
    }),
    component: () => import("../views/Tickets/CreatePSATicket.vue"),
  },
  {
    path: "/facilities/:facilityId/ServiceEvent/create",
    name: "CreateServiceEventTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      ticketType: ticketTypes.serviceEvent,
      facilityId: parseInt(p.params.facilityId),
    }),
    component: () => import("../views/Tickets/CreateServiceEventTicket.vue"),
  },
  {
    path: "/facilities/:facilityId/SiteVisitSummary/create",
    name: "CreateSiteVisitSummaryTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      ticketType: ticketTypes.adSiteVisitSummary,
      facilityId: parseInt(p.params.facilityId),
    }),
    component: () =>
      import("../views/Tickets/CreateSiteVisitSummaryTicket.vue"),
  },
  {
    path: "/facilities/:facilityId/BusinessServiceReview/create",
    name: "CreateBusinessServiceReviewTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      ticketType: ticketTypes.businessServiceReview,
      facilityId: parseInt(p.params.facilityId),
    }),
    component: () =>
      import("../views/Tickets/CreateBusinessServiceReviewTicket.vue"),
  },
  {
    path: "/facilities/:facilityId/LeadershipUpdate/create",
    name: "CreateLeadershipUpdateTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      ticketType: ticketTypes.leadershipUpdate,
      facilityId: parseInt(p.params.facilityId),
    }),
    component: () =>
      import("../views/Tickets/CreateLeadershipUpdateTicket.vue"),
  },
  {
    path: "/facilities/:facilityId/ageStrongSiteVisitSummaryTicket/create",
    name: "CreateAgeStrongSiteVisitSummaryTicket",
    props: (p) => ({
      associatedTicketId: parseInt(p.params.associatedTicketId),
      ticketType: ticketTypes.ageStrongSiteVisitSummary,
      facilityId: parseInt(p.params.facilityId),
    }),
    component: () =>
      import("../views/Tickets/CreateAgeStrongSiteVisitSummaryTicket.vue"),
  },
  {
    path: "/psa",
    name: "PSATickets",
    component: () => import("../views/Tickets/PSATicketList.vue"),
  },
  {
    path: "/defects",
    name: "DefectTickets",
    component: () => import("../views/Tickets/DefectTicketList.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("../views/Contacts.vue"),
  },
  {
    path: "/contacts/add",
    name: "AddContact",
    props: (p) => ({
      facilityId: parseInt(p.params.facilityId),
      parentCompanyId: parseInt(p.params.parentCompanyId),
    }),
    component: () => import("../views/AddContact.vue"),
  },
  {
    path: "/contacts/:contactId",
    name: "Contact",
    props: (p) => ({ id: parseInt(p.params.contactId), isCRM: false }),
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/admincontact/:contactId",
    name: "AdminContact",
    props: (p) => ({ id: parseInt(p.params.contactId), isCRM: true }),
    component: () => import("../views/Contact.vue"),
  },
  {
    path: "/tickets",
    name: "Tickets",
    component: () => import("../views/Tickets/Tickets.vue"),
  },
  {
    path: "/parentCompanyTickets",
    name: "ParentCompanyTickets",
    component: () => import("../views/Tickets/ParentCompanyTickets.vue"),
  },
  {
    path: "/tickets/:id",
    name: "Ticket",
    props: (p) => ({ id: parseInt(p.params.id) }),
    component: () => import("../views/Tickets/Ticket.vue"),
  },
  {
    path: "/parentcompanytickets/:id",
    name: "ParentCompanyTicket",
    props: (p) => ({ id: parseInt(p.params.id) }),
    component: () => import("../views/Tickets/Ticket.vue"),
  },
  {
    path: "/user/:id",
    name: "User",
    props: (p) => ({ id: parseInt(p.params.id) }),
    component: () => import("../views/User.vue"),
  },
  {
    path: "/userList",
    name: "All Users",
    component: () => import("../views/UserList.vue"),
  },
  {
    path: "/ehrs",
    name: "EHRs",
    component: () => import("../views/EHRs.vue"),
  },
  {
    path: "/serviceProviders",
    name: "ServiceProviders",
    component: () => import("../views/ServiceProviders.vue"),
  },
  {
    path: "/facilitytypes",
    name: "FacilityTypes",
    component: () => import("../views/FacilityTypes.vue"),
  },
  {
    path: "/pharmacies",
    name: "Pharmacies",
    component: () => import("../views/Pharmacies.vue"),
  },
  {
    path: "/Job",
    name: "Jobs",
    component: () => import("../views/Job/Jobs.vue"),
  },
  {
    path: "/Job/:id",
    name: "Job",
    props: (p) => ({ id: parseInt(p.params.id) }),
    component: () => import("../views/Job/Job.vue"),
  },
  {
    path: "/Job/ServiceEvent/Create",
    name: "CreateServiceEventJob",
    component: () => import("../views/Job/CreateServiceEventJob.vue"),
  },
  {
    path: "/ConsultantSchedules",
    name: "ConsultantSchedules",
    component: () => import("../views/ConsultantSchedules.vue"),
  },
  {
    path: "/mySchedule",
    name: "MySchedule",
    component: () => import("../views/MySchedule.vue"),
  },
  {
    path: "/signout",
    name: "SignOut",
    component: () => import("../views/Signout.vue"),
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.afterEach((_to, from) => {
  const isInternalBack = lastRoute.getClearInternalBack();
  if (!isInternalBack) {
    lastRoute.addLastPath(from?.path ?? "/");
  }

  if (!isUpToDate()) {
    logInformation("New version available. Auto Refresh.", "router/index.js");
    window.location.reload(true);
  }
});

export default router;
