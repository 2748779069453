<template>
  <div>
    <span v-if="!isEditing">{{ notEditingDisplay }}</span>
    <v-text-field
      v-if="isEditing"
      outlined
      dense
      v-model="internalValue"
      hide-spin-buttons
      :hide-details="hideDetails"
      v-bind="$attrs"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: "FieldText",
  components: {},
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
    value: {
      type: null,
      required: true,
    },
    hideDetails: {
      type: [Boolean, String],
      default: false,
    },
    emptyValue: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    internalValue: "",
  }),
  computed: {
    notEditingDisplay() {
      return this.internalValue || this.emptyValue;
    },
  },
  watch: {
    internalValue: {
      handler() {
        this.$emit("input", this.internalValue);
      },
    },
    value: {
      immediate: true,
      handler() {
        this.internalValue = this.value;
      },
    },
    editing: {
      handler() {
        this.internalValue = this.value;
      },
    },
  },
};
</script>
