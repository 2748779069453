<template>
  <div>
    <v-row no-gutters class="mt-3">
      <v-col cols="12">
        <v-btn
          tile
          text
          block
          class="d-flex justify-space-between px-0"
          @click="toggleCollapse"
          v-if="collapsible"
        >
          <div class="section-title">{{ title }}</div>
          <v-icon v-if="collapsible" class="icon-style">
            {{ collapseIcon }}
          </v-icon>
        </v-btn>
        <span v-if="!collapsible" class="section-title">{{ title }}</span>
      </v-col>
    </v-row>
    <v-divider class="section-divider mb-4"></v-divider>
  </div>
</template>

<script>
export default {
  name: "FieldSectionTitle",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      required: false,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    collapsed: false,
  }),
  computed: {
    collapseIcon() {
      return this.collapsed ? "mdi-chevron-up" : "mdi-chevron-down";
    },
  },
  methods: {
    toggleCollapse() {
      this.collapsed = !this.collapsed;
      this.$emit("input", this.collapsed);
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.collapsed = this.value;
      },
    },
  },
};
// todo: consider moving style to central location
</script>

<style scoped>
.section-title {
  font-weight: 400;
  font-size: 1.175rem !important;
  line-height: 1.75rem;
  letter-spacing: 0.0125rem !important;
  font-family: "Roboto", sans-serif !important;
  text-transform: none;
  color: #00000099;
}

.icon-style {
  color: #00000099 !important;
}

.section-divider {
  border-top-width: 1.5px;
}
</style>
