import { defineStore } from "pinia";
import facilityDataAccess from "@/dataAccess/facilityDataAccess";
import * as lcache from "@/util/localCache";
import { setOrLoadWithWaitAsync } from "@/util/storeUtils";

// This store serves as the list of all facilities. It's dumb right now, can be made less dumb in the future.
export const useFacilityListStore = defineStore("facilityListStore", {
  state: () => {
    const _allFacilityOptions = [];
    const _allFacilities = [];

    const facilityOptionsKey = "facilityListKey";

    const _loadingFacilities = false;

    return {
      _allFacilityOptions,
      _allFacilities,
      facilityOptionsKey,
      _loadingFacilities,
    };
  },
  getters: {
    allFacilityOptions: (state) => state._allFacilityOptions ?? [],
    pharmacyFacilityOptions: (state) =>
      state._allFacilityOptions?.filter((f) => f.isPharmacyProvider ?? false) ??
      [],
    rehabFacilityOptions: (state) =>
      state._allFacilityOptions?.filter((f) => f.isRehabProvider ?? false) ??
      [],
    allFacilities: (state) => state._allFacilities ?? [],
  },
  actions: {
    async saveFacility(facility) {
      if (facility.nurseConsultantId === undefined) {
        facility.nurseConsultantId = facility.nurseConsultant?.id;
      }

      if (facility.pharmacistConsultantId === undefined) {
        facility.pharmacistConsultantId = facility.pharmacistConsultant?.id;
      }

      if (facility.technicianConsultantId === undefined) {
        facility.technicianConsultantId = facility.technicianConsultant?.id;
      }

      const fda = new facilityDataAccess();
      const facResp = await fda.editFacilitySchedule(facility);

      if (facResp.errors) {
        return facResp.errors;
      }

      // update our internal data with the new data.
      const idx = this._allFacilities.findIndex((f) => f.id === facility.id);
      if (idx !== undefined) {
        this._allFacilities[idx] = facResp.data;
      }

      return null;
    },
    async loadFacilityOptions() {
      const facilityAccess = new facilityDataAccess();

      return await setOrLoadWithWaitAsync(
        this.facilityOptionsKey,
        (val) => {
          this._allFacilityOptions = val;
        },
        () => this._loadingFacilities,
        (val) => {
          this._loadingFacilities = val;
        },
        () => facilityAccess.getAllOptions()
      );
    },
    async loadAllFacilities() {
      const fda = new facilityDataAccess();
      const facResp = await fda.getAll();

      if (facResp.errors) {
        return facResp.errors;
      }

      this._allFacilities = facResp.data;

      return null;
    },
    clearFacilityOptions() {
      lcache.Clear(this.facilityOptionsKey);
    },
  },
});
